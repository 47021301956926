
















import {
  defineComponent,
} from '@nuxtjs/composition-api';

import { APP_ROUTER_PATHS } from '~/constants';
import { useUser } from '~/modules/customer/composables/useUser';

export default defineComponent({
  name: 'AccountTab',

  setup() {
    const { isAuthenticated } = useUser();

    const linksConfig = {
      loggedIn: [
        {
          value: 'Account Information',
          path: APP_ROUTER_PATHS.accountInfo,
        },
        {
          value: 'Address Book',
          path: APP_ROUTER_PATHS.accountAddress,
        },
        {
          value: 'My Orders',
          path: APP_ROUTER_PATHS.orders,
        },
        {
          value: 'My Credit Cards',
          path: APP_ROUTER_PATHS.accountCards,
        },
        {
          value: 'Newsletter Subscriptions',
          path: APP_ROUTER_PATHS.newsletter,
        },
        {
          value: 'My Product Reviews',
          path: APP_ROUTER_PATHS.productReviews,
        },
        {
          value: 'My Saved Printers',
          path: APP_ROUTER_PATHS.savedPrinters,
        },
        {
          value: 'SpeedyReorder',
          path: APP_ROUTER_PATHS.speedyReorder,
        },
        {
          value: 'Sign Out',
          path: APP_ROUTER_PATHS.logout,
        },
      ],
      notLoggedIn: [
        {
          value: 'Sign in',
          path: APP_ROUTER_PATHS.account,
        },
        {
          value: 'SpeedyReorder',
          path: APP_ROUTER_PATHS.speedyReorder,
        },
        {
          value: 'Order Management',
          path: APP_ROUTER_PATHS.guestOrders,
        },
      ],
    };

    return {
      links: isAuthenticated.value ? linksConfig.loggedIn : linksConfig.notLoggedIn,
      isAuthenticated,
    };
  },
});
